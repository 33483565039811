import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import CarouselBg from "../components/CarouselBg"
import Pagination from "../components/Pagination"
import Image from "gatsby-image"
import has from "lodash.has"
import { css } from "@emotion/core"
import slugify from "../util/slugify"

export default function News({ data, pageContext }) {
  const { current, pages, fieldValue: tagValue } = pageContext
  return (
    <Layout>
      <CarouselBg>
        {" "}
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-right in-pic-height">
              <div className="in-text-holder">NEWS</div>
              <div
                className="in-pic-1 in-pic-height"
                style={{ float: "right" }}
              >
                {has(data, "in_pic.sharp.fluid") && (
                  <Image
                    style={{ height: "100%" }}
                    fluid={data.in_pic.sharp.fluid}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </CarouselBg>
      <div className="breadcrums">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Link to="/">Home</Link> - News
            </div>
          </div>
        </div>
      </div>
      <div className="content-holder">
        <div className="container">
          <div className="row">
            <div
              className="col-sm-4"
              css={css`
                /* @md */
                @media (max-width: 767px) {
                  margin-bottom: 4rem;
                }
              `}
            >
              {/* <a href="#" className="news-cat nc-active">
                Lorem ipsum dolor sit amet
              </a> */}
              {data.tags.group.map((tag) => {
                return (
                  <Link
                    to={`/blog/tag/${slugify(tag.fieldValue)}`}
                    className={`news-cat ${
                      tag.fieldValue == tagValue ? "nc-active" : ""
                    }`}
                  >
                    {" "}
                    {tag.fieldValue}
                  </Link>
                )
              })}
            </div>
            <div className="col-sm-8">
              {has(data, "posts.nodes") &&
                data.posts.nodes.map((post, i) => {
                  return (
                    <React.Fragment key={post.id}>
                      {i !== 0 && <div className="divider-news" />}
                      <div className="row">
                        <div className="col-sm-2 text-regul">
                          <div className="date-2">{post.frontmatter.date}</div>
                        </div>
                        <div className="col-sm-10">
                          <Link
                            to={`/${post.frontmatter.slug}/`}
                            className="news-link"
                          >
                            {post.frontmatter.title}
                          </Link>
                          <p>{post.frontmatter.excerpt}</p>
                          <Link
                            to={`/${post.frontmatter.slug}/`}
                            className="rmore-2"
                          >
                            read more
                          </Link>
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })}

              <div className="divider-news" />
              <div className="pages">
                {current && pages && (
                  <Pagination {...{ current, pages }}></Pagination>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($skip: Int, $postsPerPage: Int, $fieldValue: String) {
    in_pic: file(publicURL: { regex: "/in_pic_2.png/" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 641) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    posts: allMarkdownRemark(
      limit: $postsPerPage
      skip: $skip
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { tags: { in: [$fieldValue] } } }
    ) {
      nodes {
        id
        frontmatter {
          title
          slug
          date(formatString: "DD/MM/YYYY")
          excerpt
        }
      }
    }
    tags: allMarkdownRemark {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`

