import React from "react"
import { Link } from "gatsby"
export default function Pagination({ current, pages }) {
  return (
    <>
      {Array.from({ length: pages }).map((_, i) => {
        return (
          <React.Fragment key={i}>
            {current == i + 1 ? (
              <Link to={`/blog/${i + 1}/`} className={`active`}>
                {i + 1}
              </Link>
            ) : (
              <Link to={`/blog${i == 0 ? "/" : "/" + (i + 1)}`}>{i + 1}</Link>
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}
